
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AutocompleteBase } from '@gsk-tech/gsk-autocomplete/gsk-autocomplete-base';

interface AutocompleteEvent extends CustomEvent {
  detail: {
    selectedChoices?: { label: string; value: any }[];
    selectedChoice?: { label: string; value: any };
    value: string;
  };
}

@Component({
  inheritAttrs: false,
})
export default class GAutocomplete extends Vue {
  @Prop(Object) readonly value!: undefined | { label: string; value: string };
  @Prop(Array) readonly choices!: AutocompleteBase['choices'];
  @Prop({ type: Function, required: false }) readonly getChoices!: AutocompleteBase['getChoices'];
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly async!: boolean;
  @Prop({ default: 'Invalid input' }) readonly validationMessage!: string;

  get v() {
    return this.value ? this.value.value : '';
  }

  async mounted() {
    if (this.v) {
      Vue.nextTick(async () => {
        const ac = this.$refs.ac as AutocompleteBase;
        ac.choices = this.choices;
        await ac.updateComplete;
        ac.value = this.v;
        // eslint-disable-next-line
        // @ts-ignore // needed in order to make initial value work for some reason
        ac.inputValue = this.v;
      });
    }
  }

  get autoCompleteEventHandlers() {
    return {
      closed: (e: Event) => e.stopPropagation(),
      opened: (e: Event) => e.stopPropagation(),
    };
  }

  input(e: AutocompleteEvent) {
    const keys = new Set(Object.keys(e.detail));
    if (keys.has('selectedChoice')) {
      this.$emit('input', e.detail.selectedChoice);
    } else if (keys.has('selectedChoices')) {
      this.$emit('input', e.detail.selectedChoices?.[0]);
    }
  }
}
